const baseUrls = {
  // 门店管理
  store: "/api/repository/store",
  // 库位管理
  warehouse: "/api/repository/warehouse",
  // 库存管理
  inventory: "/api/repository/inventory",
  // 库存管理
  inventoryOut: "/api/repository/inventoryOut",
  // 盘点单
  inventorySheet: "/api/repository/inventorySheet",
  // 盘点记录
  inventoryRecords: "/api/repository/inventoryRecords",
  // 出库单
  repInventoryOrderOut: "/api/repository/repInventoryOrderOut",
  // 入库单
  repInventoryOrderIn: "/api/repository/repInventoryOrderIn",
  // 出库单商品
  repInventoryOrderDetailOut: "/api/repository/repInventoryOrderDetailOut",
  // 入库单商品
  repInventoryOrderDetailIn: "/api/repository/repInventoryOrderDetailIn",
  // 设备管理
  device: "/api/system/device",
  // 设备管理 - 终端
  terminal: "/api/system/terminal",
  deviceLog: "/api/system/deviceLog",
  // 数据管理 - 营业概括
  tradeOverview: "/api/statistics/tradeOverview",
  // 数据管理 - 门店统计
  storeCount: "/api/statistics/storeCount",
  // 数据管理 - 配送分析
  deliveryAnalysis: "/api/statistics/deliveryAnalysis",
  // 数据管理 - 渠道分析
  channelAnalysis: "/api/statistics/channelAnalysis",
  // 数据管理 - 商品分析
  goodsAnalysis: "/api/statistics/goodsAnalysis",
  // 设置模块 - 商戶管理
  merchant: "/api/repository/merchant",
  // 设置模块 - 账号管理 - 用户管理
  user: "/api/system/user",
  // 设置模块 - 账号管理 - 菜单管理
  menu: "/api/system/menu",
  // 设置模块 - 账号管理 - 角色管理
  role: "/api/system/role",
  // 设置模块 - 配送设置
  deliverySettings: "/api/system/deliverySettings",
  // 设置模块 - 通知设置
  // 设置模块 - 操作日志
  log: "/api/system/log",
  // 商铺模块 - 商铺管理
  shop: "/api/repository/stoShop",
  // 拣货单
  ordPicking: "/api/order/ordPicking",
  // 订单模块
  orderMain: "/api/order",
  // 订单模块 - 订单处理
  order: "/api/order/order",
  // 订单模块 - 取消记录
  refund: "/api/order/refund",
  // 订单模块 - 配送信息
  delivery: "/api/order/order/ordDelivery",
  // 订单模块 - 配送信息详情
  deliveryInfo: "/api/order/order/ordDeliveryInfo",
  // 订单模块 - 货品
  orderGoods: "/api/order/order/goods",
  // 货品库存管理
  goods: "/api/repository/repGoods",
  // websocket连接
  ws: process.env.VUE_APP_WEBSOCKET_URL,
  // 呼叫中心
  call: "/api/call/call",
  callBase: "/api/call/sys/sysCall",
  callSetting: "/api/call/sys/sysCallSetting",
  callStatusReport: "/api/call/sys/sysCallStatusRecord",
  callCdrReport: "/api/call/sys/sysCallCdrReport",
  callAnswerLog: "/api/call/sys/sysAnswerCallLog",
  callRingLog: "/api/call/sys/sysCallRingLog",
  callEndLog: "/api/call/sys/sysCallEndLog",
  callBind: "/api/call/sys/sysCallBind",
  callDataCount: "/api/call/sys/callDataCount",
  // 文件上传
  file: "/api/file",
  third: "/api/order-mq/third",
  mqdelivery: "/api/order-mq/delivery",
  mqrefund: "/api/order-mq/refund",
  mqorderIm: "/api/order-mq/orderIm",
};

export default baseUrls;
